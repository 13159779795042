// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b5773c21-1b7f-4839-ac00-cb6b0c3acdbb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TfISSG92y",
    "aws_user_pools_web_client_id": "2lqef66f8uoov4h32qgplvd108",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "excerptAPI",
            "endpoint": "https://uzawsstio5.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "typing-20190610161504-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3adj2xmz5ana7.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "results-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
