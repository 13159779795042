import React from "react";
import { Button } from "shards-react";
import "../styles.css";

const Intro = (props) => {
    const handleClick = () => {
        props.history.push("/typing");
    }

    return (
        <div className="column">
            <Button onClick={handleClick}>Start</Button>
        </div>
    );
}

export default Intro;