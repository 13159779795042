import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import awsmobile from "./aws-exports"; 
import Amplify from "aws-amplify";
import Container from './components/container/Container';
console.log(Container);

Amplify.configure(awsmobile);


class App extends Component {
  render = () => {
    return (
      <BrowserRouter>
        <div className="App">
          <header className="App-header">
            <p><span id="logo">T</span>.ninja</p>
            <Container/>
          </header>
          <div className="App-footer">
            <span>This is me on </span>
            <a href="https://github.com/EDToaster">github</a>
            <span> and </span>
            <a href="https://uoft.dev">uoft.dev</a>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
