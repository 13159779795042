import React, { Component } from "react";

import "./TextView.css";

class TextBubble extends Component {

    render = () => {

        const { value, current } = this.props;
        return (
            <span className={`textbubble ${current ? "current" : ""}`}>
                {value}
            </span>
        );
    };
}

export default TextBubble;