import React, { Component } from "react";
import { FormInput, Progress, Tooltip } from "shards-react";
import { API } from "aws-amplify";

import TextBubble from "./TextBubble";

import "./TextView.css";

class TextView extends Component {

    state = { 
        text: ["Fetching random words ..."], textInput: "", disabled: true, valid: false, invalid: false, started: false, finished: false,
        length: 0, charLength: 0, progress: 0, correct: 0, correctChars: 0,
        startTime: 0, endTime: 0, elapsedTime: 0,
        CPM: false, CCPM: false, WPM: false, CWPM: false,
    };

    componentDidMount = async () => {
        const { excerpt } = await API.get("excerptAPI", "/excerpt");
        const { length } = excerpt;
        this.setState({ text: excerpt, disabled: false, length });
    }

    handleStart = (state) => {
        if (state.started) return state;

        state.startTime = Date.now();
        state.started = true;

        return state;
    }

    handleFinished = (state) => {
        state.finished = true;
        state.disabled = true;

        state.endTime = Date.now();
        state.elapsedTime = (state.endTime - state.startTime) / 60000.0;

        return state;
    }

    handleNewWord = (state) => {
        const [ word, ...text ] = this.state.text;
        const correct = this.state.valid;

        state.text = text;
        
        state.progress++;
        
        if (text.length === 0) {
            state = this.handleFinished(state);
        }

        if (correct) {
            state.correct++;
            state.correctChars += word.length;
        }

        state.charLength += state.textInput.length;

        state.textInput = "";

        return state;
    }

    handleTextChanged = (state) => {
        if (!state.started) {
            state = this.handleStart(state);
        }

        if (state.textInput.length === 0) {
            state.valid = false;
            state.invalid = false;
        } else {
            const { textInput, text } = state;
            const [ target ] = text;

            const starts = target.startsWith(textInput);

            state.valid = starts;
            state.invalid = !starts;
        }

        return state;
    }

    handleChange = (event) => {
        const { target } = event;
        const { value } = target;

        const { textInput } = this.state;

        let state = this.state;

        if (textInput.length < value.length) {
            // input
            const character = value.charAt(value.length-1);
            if (character === " ") {
                state = this.handleNewWord(state);
            } else {
                state.textInput = value;
            }
        } else {
            state.textInput = value;
        }

        state = this.handleTextChanged(state);

        this.setState(state);
    }

    toggleTooltip = (target) => () => {
        const open = this.state[target];
        this.setState({ [target]: !open });
    }

    render = () => {

        const {length, charLength, correct, correctChars, elapsedTime, progress, valid, invalid, disabled, finished} = this.state;

        console.log( charLength);

        return (
            <div>
                
                {
                    finished ? 
                        <div>
                            <div id="CPM">{`CPM: ${Math.round(charLength / elapsedTime)}`}</div>
                            <div id="CCPM">{`CCPM: ${Math.round(correctChars / elapsedTime)}`}</div>
                            <div className="space"></div>
                            <div id="WPM">{`WPM: ${Math.round(length / elapsedTime)}`}</div>
                            <div id="CWPM">{`CWPM: ${Math.round(correct / elapsedTime)}`}</div>
                            <Tooltip open={this.state.CPM} placement="right" target="#CPM" toggle={this.toggleTooltip("CPM")}>Characters per Minute</Tooltip>
                            <Tooltip open={this.state.CCPM} placement="right" target="#CCPM" toggle={this.toggleTooltip("CCPM")}>Corrected Characters per Minute</Tooltip>
                            <Tooltip open={this.state.WPM} placement="right" target="#WPM" toggle={this.toggleTooltip("WPM")}>Words per minute</Tooltip>
                            <Tooltip open={this.state.CWPM} placement="right" target="#CWPM" toggle={this.toggleTooltip("CWPM")}>Corrected Words per Minute</Tooltip>
                        </div>
                    :
                        <div>
                            <div id="textprogress">
                                <Progress value={100 * progress / length} theme={"success"}></Progress>
                            </div>
                            <div id="textview">
                            {
                                this.state.text.map((text, i) => (
                                    <TextBubble value={text} current={i === 0 && !disabled} key={i}></TextBubble>
                                ))
                            }
                            </div>
                            <FormInput 
                                disabled={disabled} 
                                valid={valid}
                                invalid={invalid}
                                id="textinput" 
                                placeholder="Type here" 
                                size="lg" 
                                onChange={this.handleChange} 
                                value={this.state.textInput}></FormInput>
                        </div>
                    
                }
            </div>
        );
    };
}

export default TextView;