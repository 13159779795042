import React from "react";
import { Button } from "shards-react";
import TextView from "./TextView";

import "../styles.css";

const Typing = (props) => {
    const handleClick = () => {
        props.history.push("/");
    }

    return (
        <div className="column">
            <TextView></TextView>
            <Button onClick={handleClick}>Back</Button>
        </div>
    )
}


export default Typing;